up.compiler('[auto-refresh]', (element, { after, href }) => {
  const timeout = setTimeout(refresh, after * 1000)

  function refresh() {
    up.render({ target: element, url: href, hungry: false, history: false, cache: false })
  }

  return () => {
    clearTimeout(timeout)
  }
})
