import { targetInsideModal } from '../util/modal_helper'

up.compiler('form', (element) => {

  if (targetInsideModal(element)) {
    // Forms inside modals should never update the URL, unless their target points outside the modal.
    element.setAttribute('up-history', 'false')
  }

})
