up.compiler('[selection-actions]', (element, data) => {

  const target = up.element.get(data.target)
  if (!target) return

  function updateElement() {
    const hasSelection = !!target.querySelector(':checked')
    up.element.toggleClass(element, '-has-selection', hasSelection)
  }

  updateElement()
  up.on(target, 'change', 'input[type=checkbox]', updateElement)

})
