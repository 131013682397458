import { targetInsideModal, elementInsideModal } from '../util/modal_helper'
import { signedOut } from '../util/current_user'

function getDefaultTarget(link) {
  if (link.getAttribute('up-layer') !== 'root' && elementInsideModal(link)) {
    return '.content'
  } else {
    return '.content, .sidebar'
  }
}

up.macro('[content-link]', (link) => {
  if (signedOut()) return

  let target = link.getAttribute('content-link') || getDefaultTarget(link)

  let attrs = {
    'up-target': target,
    'up-preload': '',
    'up-instant': '',
    'up-history': 'true',
  }

  if (link.getAttribute('content-link-preload') == 'false') {
    delete attrs['up-preload']
  }

  if (targetInsideModal(link, target)) {
    attrs['up-history'] = 'false'
  }

  if (link.matches('[up-dismiss]') && elementInsideModal(link)) {
    // Workaround for https://github.com/unpoly/unpoly/issues/70
    delete attrs['up-target']
  }

  // It feels wrong for a button
  if (link.matches('.btn:not(.btn-link)')) {
    delete attrs['up-instant']
  }

  up.element.setAttrs(link, attrs)
})
