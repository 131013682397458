up.compiler('.sidebar-opener', (element) => {

  function onClick(event) {
    event.preventDefault()
    toggleSidebarVisible()
  }

  function onNavigate() {
    checkIfEnabled()
    toggleSidebarVisible(false)
  }

  function checkIfEnabled() {
    up.util.task(() => {
      const sidebar = up.element.get('.page--sidebar')
      const enabled = !!(sidebar?.matches(':not(:empty)'))
      up.element.toggleClass(element, '-enabled', enabled)
    })
  }

  function toggleSidebarVisible(isVisible) {
    up.util.task(() => {
      up.element.toggleClass(document.body, '-mobile-sidebar', isVisible) // will toggle when argument is missing
    })
  }

  element.addEventListener('click', onClick)

  checkIfEnabled()
  return [
    up.on('up:location:pushed', onNavigate)
  ]

})

