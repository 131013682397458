import Selectr from 'mobius1-selectr'

up.compiler('[combo-box]', (element, { path, type }) => {
  const selectr = new Selectr(element, {
    renderOption,
    pagination: path ? 20 : null,
  })

  const label = element.closest('form').querySelector(`[for="${element.id}"]`)
  if ( label ) {
    label.addEventListener('click', () => {
      setTimeout(() => {
        selectr.open()
      })
    })
  }

  selectr.on('selectr.init', () => {
    if ( path ) {
      up.request(path).then((response) => {
        let options = JSON.parse(response.text)
        selectr.add(options)
      })
    }
  })

  selectr.on('selectr.open', () => {
    setTimeout(() => {
      let selected = document.querySelector('.selectr-option.selected')
      if ( selected ) {
        selected.scrollIntoView({
          block: 'nearest',
        })
      }
    })
  })

  return () => {
    selectr.destroy()
  }


  function renderOption(option) {
    if ( type == 'event' ) {
      return `<div class="combo-box-option">
        <span class="calendar-badge combo-box-option--badge -${option.getAttribute('calendar_color')}"></span>
        ${createSpan(option.getAttribute('calendar_name'), 'combo-box-option--muted -calendar')}
        ${createSpan(option.getAttribute('date'), 'combo-box-option--muted -date')}
        ${createSpan(option.getAttribute('name'))}
      </div>`
    } else if ( type == 'calendar' ) {
      return `<div class="combo-box-option">
        <span class="calendar-badge combo-box-option--badge -${option.getAttribute('calendar_color')}"></span>
        ${createSpan(option.innerText)}
      </div>`
    } else {
      return `<div class="combo-box-option">
        ${createSpan(option.getAttribute('option_text') || option.innerText)}
      </div>`
    }
  }

  function createSpan(text, klass) {
    let element = document.createElement('span')
    element.innerText = text
    if ( klass ) {
      element.className = klass
    }
    return element.outerHTML
  }
})
