up.compiler('[scroll-to-top]', element => {

  function scrollToTop(event) {
    event.preventDefault()
    let scrollingElement = document.scrollingElement || document.documentElement

    if (scrollingElement.scroll) {
      scrollingElement.scroll({ top: 0, behavior: 'smooth' })
    } else {
      scrollingElement.scrollTop = 0
    }

    element.blur()
  }

  function handleVisibility() {
    let scrolledTo = window.scrollY
    let scrolledBy = scrolledTo > 0 ? scrolledTo / (document.body.scrollHeight - window.innerHeight) : 0

    up.element.toggleClass(element, '-visible', scrolledTo > 300 || scrolledBy > .25)
    up.element.toggleClass(element, '-end', scrolledBy === 1)
  }

  window.addEventListener('scroll', handleVisibility)
  element.addEventListener('click', scrollToTop)

  handleVisibility()

})
