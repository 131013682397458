up.compiler('[event-form]', (element) => {

  const allDaySelector = 'input[type="checkbox"][name*="all_day"]'

  function onChangeAllDay() {
    const allDay = element.querySelector(allDaySelector)
    if (!allDay) return

    element.querySelectorAll('[datetime-picker]').forEach((element) => {
      up.emit(element, 'app:dateTimePicker:allDayChanged', { allDay: allDay.checked })
    })
  }

  up.on(element, 'change', allDaySelector, onChangeAllDay)
  onChangeAllDay()

})
