function cookiesByName() {
  let hash = {}

  Array.from(document.cookie.split(/;\s*/)).forEach(string => {
    let nameAndValue = string.split('=')
    let name = nameAndValue[0]
    let value = nameAndValue[1]
    hash[name] = decodeURIComponent(value)
  })

  return hash
}

export function getCookie(name) {
  return cookiesByName()[name]
}
