export function addLeavePageCheck(handler) {
  const leaveMessage = 'Leave page? Changes you made may not be saved.'
  const closeMessage = 'Close dialog? Changes you made may not be saved.'

  function mayLeave(container) {
    let result = true
    handler({
      closingElement: container || document.body,
      preventLeave() {
        result = false
      },
    })
    return result
  }

  handler.eventHandlerForBeforeUnload = function(event) {
    if ( !mayLeave() ) {
      /*
        Show the default "Are you sure to leave?" dialog. It's text cannot be controlled.
        https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      */
      event.preventDefault()
      event.returnValue = leaveMessage
      return leaveMessage
    }
  }

  handler.eventHandlerForLinkFollow = function(event) {
    if ( !event.target.getAttribute('up-modal') && !event.target.getAttribute('data-method') ) {
      if ( !mayLeave() && !confirm(leaveMessage) ) {
        event.preventDefault()
      }
    }
  }

  handler.eventHandlerForModalClose = function(event) {
    if ( !mayLeave(document.querySelector('.up-modal')) && !confirm(closeMessage) ) {
      event.preventDefault()
    }
  }

  window.addEventListener('beforeunload', handler.eventHandlerForBeforeUnload)
  document.addEventListener('up:link:follow', handler.eventHandlerForLinkFollow)
  document.addEventListener('up:layer:dismiss', handler.eventHandlerForModalClose)
}

export function removeLeavePageCheck(handler) {
  window.removeEventListener('beforeunload', handler.eventHandlerForBeforeUnload)
  document.removeEventListener('up:link:follow', handler.eventHandlerForLinkFollow)
  document.removeEventListener('up:layer:dismiss', handler.eventHandlerForModalClose)
}
