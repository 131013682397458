up.compiler('input.custom-file-input[type=file]', (element) => {
  const container = element.closest('.form-group')
  const label = container.querySelector('.custom-file-label')
  const preview = container.querySelector('.custom-file-preview')
  const info = container.querySelector('.file-info')
  const removeLink = container.querySelector('[remove-file-link]')
  const removeInput = container.querySelector('[remove-file-input]')

  element.addEventListener('change', function() {
    let filename = String(this.value)
    let basename = filename.replace(/^.*[\\\/]([^\\\/]+)$/, '$1')

    setRemoveInput(!this.value)
    setLabel(basename)
    updatePreview(basename)
  })

  if (removeLink) {
    removeLink.addEventListener('click', function(event) {
      event.preventDefault()
      setRemoveInput(true)

      info.classList.add('d-none')
      if (preview) preview.classList.add('d-none')

      return false
    })
  }

  function setRemoveInput(remove) {
    removeInput.value = remove ? '1' : '0'
  }

  function setLabel(text) {
    label.innerText = text
  }

  function updatePreview(basename) {
    if (!preview) return

    if (/\.(jpe?g|png)/.test(basename)) {
      let file = element.files[0]
      readImageAsDataURL(file).then((dataURL) => {
        preview.src = dataURL
        preview.classList.remove('d-none')
      })
    } else {
      preview.classList.add('d-none')
    }
  }

  function readImageAsDataURL(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.addEventListener('load', () => {
        resolve(reader.result)
      })
      reader.readAsDataURL(file)
    })
  }
})
