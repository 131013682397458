import Selectr from 'mobius1-selectr'

up.compiler('[multi-select]', (element, data) => {
  const taggable = data?.taggable
  const selectr = new Selectr(element, { multiple: true, taggable: taggable })

  const label = element.closest('form').querySelector(`[for="${element.id}"]`)
  if ( label ) {
    label.addEventListener('click', () => {
      setTimeout(() => {
        selectr.open()
      })
    })
  }

  selectr.on('selectr.open', () => {
    setTimeout(() => {
      let selected = document.querySelector('.selectr-option.selected')
      if ( selected ) {
        selected.scrollIntoView({
          block: 'nearest',
        })
      }
    })
  })

  return () => {
    selectr.destroy()
  }
})
