import { messageCountService } from '../services/message_counts'

up.compiler('[navbar-message-counts]', (element, data) => {
  let channelsSelector = '.navbar-message-counts--counter.-channels'
  let conversationsSelector = '.navbar-message-counts--counter.-conversations'
  let totalCounters = document.querySelectorAll('.navbar-message-counts--counter.-total')

  function updateCounters({ counts }) {
    let newCounts = {
      channel: 0,
      conversation: 0,
    }
    Object.entries(counts).forEach(([_key, { type, count }]) => {
      newCounts[type] += count
    })

    let channelsCounters = document.querySelectorAll(channelsSelector)
    let conversationsCounters = document.querySelectorAll(conversationsSelector)

    channelsCounters.forEach((channelCounter) => {
      updateCounter(channelCounter, newCounts.channel)
    })
    conversationsCounters.forEach((conversationCounter) => {
      updateCounter(conversationCounter, newCounts.conversation)
    })
    totalCounters.forEach((totalCounter) => {
      updateCounter(totalCounter, newCounts.channel + newCounts.conversation)
    })
  }

  function updateCounter(counter, count) {
    counter.textContent = count
    up.element.toggleClass(counter, 'd-none', count == 0)
  }

  messageCountService.addUpdateListener(updateCounters)

  return () => {
    messageCountService.removeUpdateListener(updateCounters)
  }
})
