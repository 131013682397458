up.on('click', '[checkable-cell]', (event) => {

  if (event.target.closest('input, label, .form-check')) {
    // User clicked directly on the checkbox
    return
  } else {
    event.preventDefault()
    event.stopImmediatePropagation()
    event.stopPropagation()

    const element = event.target.closest('[checkable-cell]')
    const checkable = element.querySelector('input[type=checkbox], input[type=radio]')

    if (checkable) {
      checkable.focus()
      checkable.click()
    }
  }

})
