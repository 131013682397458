up.compiler('[document-picker-button]', (element, { formSelector }) => {

  // This compiler is for the custom tinyMCE document picker dialog's
  // button. It will build a link from the selected element and the
  // form inputs and send that to the WYSIWYG editor via PostMessage.
  // Afterwards whitespaces are inserted and the dialog is closed.

  const form = up.element.get(formSelector)

  element.addEventListener('click', (event) => {
    event.preventDefault()

    let linkTag = buildLinkTag(form)
    if (linkTag == null) return

    window.parent.postMessage({
      mceAction: 'insertContent',
      content: linkTag,
    }, '*');

    // If this is omitted, all text (even blanks!) that the
    // user writes after the link will be part of the link.
    // Is necessary no matter which command is used to insert the link...
    window.parent.postMessage({
      mceAction: 'insertContent',
      content: ' ',
    }, '*')

    window.parent.postMessage({
      mceAction: 'close'
    }, '*');


  })

  function buildLinkTag(form) {
    let selectedItem = form.querySelector('input[name=document_link]:checked')
    let link = getDocumentLink(selectedItem)
    let extension = getExtension(selectedItem)
    let text = getDocumentInput(form, 'text_to_display')
    let title = getDocumentInput(form, 'title')

    if (link == null) {
      alert('Please select a document')
      return
    }

    if (text == '') {
      alert('Please fill in the text to display')
      return
    }

    let link_tag= `<a href='${link}' class='document-link -extension-${extension}' title='${title}'>${text}</a>`
    return link_tag
  }

  function getDocumentLink(selectedItem) {
    if (selectedItem == null) {
      return
    } else {
      let domain = document.location.origin
      return domain + selectedItem.dataset.link
    }
  }

  function getDocumentInput(form, name) {
    let input = form.querySelector(`input[name=${name}]`)
    return input.value
  }

  function getExtension(selectedItem) {
    if (selectedItem !== null) {
      return selectedItem.dataset.extension
    }
  }

})
