export const currentUser = {
  signedIn: false
}

export function signedIn() {
  return currentUser.signedIn
}

export function signedOut() {
  return !signedIn()
}
