up.compiler('.user-notifier', (element) => {
  list = element.closest('form').getElementsByClassName('list-group')[0]
  element.disabled = !list.hasChildNodes()

  up.on('flow:change', () => {
    element.disabled = !list.hasChildNodes()
    if(!list.hasChildNodes()){
      element.checked = false
    }
  })
})

