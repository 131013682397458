import 'lazysizes'

document.addEventListener('lazybeforeunveil', (event) => {
  const target = event.target
  const imageUrl = target.getAttribute('lazy-bg')

  if (imageUrl) {
    target.style.backgroundImage = `url("${imageUrl}")`
  }
})
