export function targetInsideModal(element, targetSelector) {
  // Detects if the given element lives inside a modal and will open its target inside a modal again.

  targetSelector = targetSelector || element.getAttribute('up-target')
  const modal = element.closest('up-modal-content')
  const layer = element.getAttribute('up-layer')

  return modal && modal.querySelector(targetSelector) && layer !== 'root'
}

export function elementInsideModal(element) {
  let modal = element.closest('up-modal')
  return modal && !modal.matches('[up-flavor="drawer"]')
}
