export default function askModal(args) {
  return new Promise(async function(resolve, reject) {
    await up.layer.open(args)
    let modalElement = document.querySelector('up-modal')
    let accepted = false

    up.on(modalElement, 'click', '[up-accept]', function(event, link) {
      accepted = true
      let value = up.element.jsonAttr(link, 'up-accept')
      resolve({ answer: value })
      up.layer.dismiss()
    })

    up.on(modalElement, 'up:layer:dismiss', function(event) {
      if (!accepted) {
        resolve({ cancelled: true })
      }
    })
  })
}
