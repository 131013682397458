// Compiler to specifically handle checkboxes of assignable values. Inputs need to have an "assignable" attribute.
//
// Here is what happens:
// - Checkboxes for unassignable values are disabled, if they are unchecked.
// - Checkboxes for unassignable values that are checked stay enabled and will be highlighted as invalid
//   from the form builder to allow unselecting them. When being unchecked, they become disabled.
// - Because of how the form builder works, if one invalid value is assigned, the entire .form-group will have
//   a class "form-group-invalid", and all its inputs will have an "is-invalid" modifier class.
//   The group modifier will be removed, and for each input of an assignable value the "is-invalid" modifier, too.
//
up.compiler('[assignable]', (element) => {

  const formGroup = element.closest('.form-group')
  if (formGroup) formGroup.classList.remove('form-group-invalid')

  function updateState() {
    const assignable = element.getAttribute('assignable')

    if (assignable === 'true') {
      element.classList.remove('is-invalid')
    }

    if (assignable === 'false' && !element.checked) {
      element.disabled = true
      element.classList.remove('is-invalid')
    }
  }

  element.addEventListener('change', updateState)
  updateState()

})
