up.compiler('.document-link', (element) => {

  // This compiler adds icons at the end of document links
  // added via the TinyMCE custom document picker dialog.

  let matcher = /^-extension-(.*)$/
  let classNames = element.className.split(' ')
  let name = classNames.find(item => item.match(matcher))
  let extension = name.match(matcher)[1]

  if (extension == '') {
    // Use generic icon
    let newElement = document.createElement('div')
    newElement.classList.add('icon', '-document', '-document-link')

    element.appendChild(newElement)
  } else {
    // Use icon with extension
    let html = `
      <svg width="20" height="20" viewBox="0 0 20 20">
        <text x="20%" y="69%" font-size="10" font-family="VW Text" font-weight="bold">${extension.toUpperCase()}</text>
      </svg>
    `
    let newElement = document.createElement('div')
    newElement.classList.add('document-icon')
    newElement.innerHTML = html

    element.appendChild(newElement)
  }
})
