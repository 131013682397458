import { messageCountService } from '../services/message_counts'

up.compiler('[remember-recent-message]', (element, { url, subjectKey, id, includeOlder }) => {
  let request = up.request(url, { method: 'POST' })
  if ( includeOlder) {
    messageCountService.markAsReadIncludingOlder(subjectKey, id)
  } else {
    request.then(() => {
      messageCountService.refresh()
    })
  }
})
