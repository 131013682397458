import { addLeavePageCheck, removeLeavePageCheck } from '../util/leave_page_check'

up.compiler('[form-unload-alert]', (element) => {
  const originalData = serializeForm()
  const submitButton = element.querySelector('button[type="submit"]')
  let submitted = false

  if ( submitButton ) {
    submitButton.addEventListener('click', () => {
      submitted = true
    })
  }

  addLeavePageCheck(checkBeforeLeave)

  return () => {
    removeLeavePageCheck(checkBeforeLeave)
  }


  function checkBeforeLeave(check) {
    if ( !submitted && check.closingElement.contains(element) && serializeForm() !== originalData ) {
      check.preventLeave()
    }
  }

  function serializeForm() {
    return up.Params.fromForm(element).toQuery()
  }
})
