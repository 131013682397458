import { inTestEnvironment } from '../util/environment'

up.compiler('[time]', { priority: 1 }, (element, { mode, time, now }) => {
  if ( !time ) {
    return
  }
  const eventDate = new Date(time)

  let nowOffset = 0
  if ( now ) {
    nowOffset = new Date() - new Date(now)
  }

  setTooltip()

  if ( mode == 'relative' ) {
    printRelativeTime()
    const interval = setInterval(printRelativeTime, eventDate < (new Date() - nowOffset - 60000) ? 10000 : 1000)
    return () => {
      clearInterval(interval)
    }
  }


  function setTooltip() {
    const timezone = inTestEnvironment() ? 'de-DE' : undefined
    let timeString = eventDate.toLocaleString(timezone)
    // remove seconds
    timeString = timeString.replace(/:(\d{1,2}):00/, ':$1')
    element.setAttribute('tooltip', `${timeString} your time`)
  }

  function printRelativeTime() {
    let now = new Date() - nowOffset
    let past = now > eventDate
    let seconds = Math.abs(now - eventDate) / 1000
    let text = secondsToProse(seconds)

    if ( now > eventDate ) {
      text = `${text} ago`
    } else {
      text = `in ${text}`
    }

    element.textContent = text
  }

  function secondsToProse(seconds) {
    if ( seconds < 60 ) {
      return pluralize(Math.round(seconds), 'second')
    }

    let minutes = seconds / 60
    if ( minutes < 60 ) {
      return pluralize(Math.round(minutes), 'minute')
    }

    let hours = minutes / 60
    if ( hours < 24 ) {
      return pluralize(Math.round(hours), 'hour')
    }

    let days = hours / 24
    if ( days < 365 ) {
      return pluralize(Math.round(days), 'day')
    }

    let years = days / 365
    return pluralize(Math.round(years), 'year')
  }

  function pluralize(amount, label) {
    if ( amount === 1 ) {
      return `1 ${label}`
    } else {
      return `${amount} ${label}s`
    }
  }
})
