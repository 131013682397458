up.compiler('[upcoming-events-toggler]', (element, data) => {

  const extractTarget = data.extractTarget

  function onClick(event) {
    event.preventDefault()
    let target = document.querySelector(extractTarget)
    let drawer = document.createElement('div')

    drawer.innerHTML = target.innerHTML
    drawer.classList.add('drawer-upcoming-events')
    drawer.classList.add('pl-3')

    up.layer.open({ content: drawer.outerHTML, mode: 'drawer', size: 'auto', link: element })
  }

  element.addEventListener('click', onClick)

})
