up.on('click', '[clickable]', (event) => {

  if (event.defaultPrevented) {
    // Another compiler explicitly wanted to stop foreign behavior (but keep the event), so we abort.
    return
  }

  if (event.target.closest('a, [checkable-cell]')) {
    // User clicked directly on a link or interactable table cell
    return
  }

  const element = event.target.closest('[clickable]')
  const linkOrButton = element.querySelector('a[href], [type=submit]')

  if (linkOrButton) {
    if (linkOrButton.matches('[up-target]')) {
      up.follow(linkOrButton)
    } else {
      linkOrButton.click()
    }
  }

})
