import { inTestEnvironment } from '../util/environment'

up.compiler('[date]', { priority: 1 }, (element, { date }) => {
  if ( !date ) {
    return
  }
  const eventDate = new Date(date)

  setTooltip()

  function setTooltip() {
    const timezone = inTestEnvironment() ? 'de-DE' : undefined
    const dateString = eventDate.toLocaleDateString(timezone)
    element.setAttribute('tooltip', dateString)
  }

})
