up.compiler('[multi-file-action]', (element, { modalUrl, form }) => {
  const formElement = up.element.get(form)

  element.addEventListener('click', openModal)


  function openModal(event) {
    event.preventDefault()
    const params = up.Params.fromForm(formElement)
    up.layer.open({
      url: modalUrl,
      params,
      method: 'post',
      target: '.content',
      history: false,
    })
    return false
  }
})
