import Tooltip from 'tooltip.js'

up.compiler('[tooltip]', element => {
  const canHover = !window.matchMedia('(hover: none)').matches
  const tooltip = new Tooltip(element, {
    title: element.getAttribute('tooltip'),
    container: document.body,
    trigger: canHover ? 'hover' : 'manual',
    closeOnClickOutside: true,
  })
  let timeout = undefined

  if ( !canHover ) {
    element.addEventListener('mousedown', () => {
      tooltip.show()
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        tooltip && tooltip.hide()
      }, 3000)
    })
  }

  return () => {
    tooltip.dispose()
    if ( timeout ) {
      clearTimeout(timeout)
    }
  }
})
