import { messageCountService } from '../services/message_counts'

up.compiler('[new-message-poll]', (element, data) => {
  let subjectKey = data.subjectKey
  let newMessageCount = element.querySelector('.new-message-count')
  let closeButton = element.querySelector('.close')
  let lastCount = undefined
  let contextLabel = data.contextLabel
  let visible = false


  function updateElement({ counts }) {
    let newCount = countNewMessages(counts)

    let countChanged = (lastCount !== undefined) && (newCount !== lastCount)
    if (countChanged) {
      let alertMessage = `There are new messages ${contextLabel}!`
      if (subjectKeyIsRecordType()) {
        alertMessage += ` Click here to show them.`
      } else {
        let humanCount = newCount === 1 ? `${newCount} new message` : `${newCount} new messages`
        alertMessage += ` Click here to show ${humanCount}.`
      }
      newMessageCount.textContent = alertMessage

      if (newCount > 0) {
        showAlert()
      } else {
        hideAlert()
      }
    }

    lastCount = newCount
  }

  function countNewMessages(counts) {
    if (subjectKeyIsRecordType()) {
      return Object.values(counts)
        .filter((countsEntry) => countsEntry.type === subjectKey)
        .reduce((count, countsEntry) => count + countsEntry.count, 0)
    } else {
      return counts[subjectKey] && counts[subjectKey].count
    }
  }

  function showAlert() {
    if (!visible) {
      element.classList.remove('d-none')
      up.animate(element, 'move-from-top')
      visible = true
    }
  }

  function hideAlert() {
    if (visible) {
      up.animate(element, 'move-to-top').then(() => {
        element.classList.add('d-none')
      })
      visible = false
    }
  }

  function subjectKeyIsRecordType() {
    return ['channel', 'conversation'].includes(subjectKey)
  }


  closeButton.addEventListener('click', (event) => {
    event.preventDefault()
    hideAlert()
  })
  messageCountService.addUpdateListener(updateElement)

  return () => {
    messageCountService.removeUpdateListener(updateElement)
  }
})
