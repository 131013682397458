import { messageCountService } from '../services/message_counts'

up.compiler('[message-counter]', (element, { subjectKey }) => {
  function onCountChange({ counts }) {
    const data = counts[subjectKey]
    if (data) setCount(data.count)
  }

  function setCount(count) {
    element.textContent = count

    up.element.toggleClass(element, 'd-none', count === 0)
  }

  messageCountService.addUpdateListener(onCountChange)
  return () => {
    messageCountService.removeUpdateListener(onCountChange)
  }
})
