import { inTestEnvironment } from './util/environment'
import { getCsrfParam, getCsrfToken } from './util/csrf'

up.protocol.config.csrfParam = getCsrfParam
up.protocol.config.csrfToken = getCsrfToken

up.form.config.observeDelay = 100
up.viewport.config.revealPadding = 100

// When no target is given, update .layout--page
up.fragment.config.mainTargets.unshift('.layout--page')
up.history.config.restoreTargets = up.fragment.config.mainTargets

if (inTestEnvironment()) {
  // Disable animations globally so Capybara doesn't see duplicate elements during transitions
  up.motion.config.enabled = false
  // When revealing elements, don't animate the scrolling
  up.viewport.config.duration = 0
  up.network.config.badResponseTime = 800
  // high delay so preload is disabled in test
  up.link.config.preloadDelay = 10 * 1000
}

// By default no links but all forms will be handled by Unpoly.
// Individual links can opt out with [up-follow=false].
// Individual forms can opt out with [up-submit=false].
up.link.config.noInstantSelectors.push('.btn')
up.form.config.submitSelectors.push('form')

up.radio.config.hungrySelectors = [
  '[up-hungry]',
  'head meta[name="robots"]',
  'head meta[name="description"]',
  'head meta[property*="og:"]',
  'head link[rel="canonical"]',
]

up.layer.config.overlay.history = false

up.on('up:layer:opened', () => { document.body.classList.add('-modal-open') })
up.on('up:layer:dismissed', () => { document.body.classList.remove('-modal-open') })

up.compiler('meta, link', (element) => {
  // Because of paranoia reasons, we remove up-source attributes on meta tags
  element.removeAttribute('up-source')
})

up.feedback.config.currentClasses.push('active')
up.form.config.validateTargets.unshift('.form-group:has(&)')

// Open large modals by default.
// Custom modal styles are found in stylesheets/frontend/unpoly/ext/modal.sass.
up.layer.config.modal.size = 'large'

// On mobile we open overlays as a drawer that fills the entire screen with a blue background.
up.layer.config.drawer.size = 'full'
up.layer.config.drawer.backdrop = false

// Drawers should slide in from the right, like the hamburger menu.
up.layer.config.drawer.openAnimation = 'move-from-right'
up.layer.config.drawer.closeAnimation = 'move-to-right'

// we have a custom loading bar compiler
up.network.config.progressBar = false
