import askModal from 'javascripts/util/ask_modal'

up.compiler('[calendar]', (element, data) => {
  const initialDate = data['initialDate']
  const initialCalendarView = data['initialCalendarView']
  // With this height the day and week view don't have their own scrollbar (chrome needs bigger hight than firefox)
  const contentHeight = 1258
  const baseRoute = data['baseRoute']
  const target = data['target']
  const sidebarPreviousMonthButtonSelector = '.sidebar-section--action > .previous-month-button'
  const sidebarNextMonthButtonSelector = '.sidebar-section--action > .next-month-button'
  const sidebarPreviousWeekButtonSelector = '.month-sheet-container .previous-week-button'
  const sidebarNextWeekButtonSelector = '.month-sheet-container .next-week-button'

  let calendarContent = null
  let previousNextButtons = null
  let currentCalendarView = null
  let calendarListView = document.querySelector('.calendar-list-view')
  let calendar = init(element)


  up.on(element, 'click', '.fc-customList-button', (event) => {
    showView('customList')
  })

  up.on(element, 'click', '.fc-timeGridWeek-button, .fc-dayGridMonth-button', (event) => {
    showView('timeGridWeek')
  })

  up.on(element, 'click', '.fc-dayGridMonth-button', (event) => {
    showView('dayGridMonth')
  })

  function showView(view) {
    if (view === 'customList') {
      hidePreviousNext()
      showCalendarListView()
    } else if (view === 'timeGridWeek') {
      showPreviousNext()
      hideCalendarListView()
    } else if (view === 'dayGridMonth') {
      showPreviousNext()
      hideCalendarListView()
    }
    currentCalendarView = view
    setRecentCalendarView(view)
  }

  function hideCalendarListView() {
    calendarListView.classList.add('d-none')
  }

  function showCalendarListView() {
    calendarListView.classList.remove('d-none')
  }

  function hidePreviousNext() {
    previousNextButtons.style.visibility = 'hidden' // hidden will keep it in the layout so space is taken and title does not jump
  }

  function showPreviousNext() {
    previousNextButtons.style.visibility = 'visible'
  }

  function setRecentCalendarView(view) {
    up.request(`${baseRoute}/remember_recent_calendar_view`, { method: 'POST', params: { view: view } })
  }

  // In list- and month-view we navigate using the sidebar, which will update things like the date,
  // header, today-button...
  // In week view however we use hidden buttons in the sidebar that switch weeks
  function onPreviousClick() {
    if (currentCalendarView === 'timeGridWeek') {
      setTimeout(() => { document.querySelector(sidebarPreviousWeekButtonSelector).click() }, 0)
    } else {
      setTimeout(() => { document.querySelector(sidebarPreviousMonthButtonSelector).click() }, 0)
    }
  }

  function onNextClick() {
    if (currentCalendarView === 'timeGridWeek') {
      setTimeout(() => { document.querySelector(sidebarNextWeekButtonSelector).click() }, 0)
    } else {
      setTimeout(() => { document.querySelector(sidebarNextMonthButtonSelector).click() }, 0)
    }
  }

  function init(element){
    loadFullCalendar()
      .then(imports => {
        const timezoneString = imports['jstz'].determine().name()

        // Plugin is only created so we get a button in the header toolbar with similar
        // behavior (active, ..). Does render nothing. The real list is shown on button click
        let customListPlugin = imports['createPlugin']({
          views: {
            customList: {
              classNames: [ 'unused-calendar-list' ],
              content: function(props) {
                let html =''
                return { html: html }
              },
              contentHeight: 0,
            },
            duration: { months: 1 },
            monthMode: true,
          }
        })

        calendar = new imports['Calendar'](element, {
          plugins: [
            imports['interactionPlugin'],
            imports['dayGridPlugin'],
            imports['timeGridPlugin'],
            imports['bootstrapPlugin'],
            customListPlugin,
          ],
          customButtons: {
            customPrevious: {
              text: '<',
              click: onPreviousClick,
            },
            customNext: {
              text: '>',
              click: onNextClick,
            },
          },
          timeZone: timezoneString,
          initialDate: initialDate,
          initialView: initialCalendarView,
          headerToolbar: {
            left: 'customPrevious,customNext',
            center: 'title',
            right: 'customList,timeGridWeek,dayGridMonth'
          },
          buttonText: {
            month: 'Month',
            week: 'Week',
            customList: 'List',
          },
          views: {
            timeGridWeek: {
              contentHeight: contentHeight,
            },
            dayGridMonth: {
              contentHeight: contentHeight,
            },
          },
          editable: false,
          locale: 'en',
          themeSystem: 'bootstrap',
          eventClick: eventClick,
          events: `${baseRoute}.json`,
          eventTimeFormat: { // like '14:30', '9:15'
            hour: 'numeric',
            minute: '2-digit',
            meridiem: false,
          },
          weekNumberCalculation: 'ISO', // will also set firstDay to Monday
        })

        let result = calendar.render()

        calendarContent = element.querySelector('.fc-view-harness')
        previousNextButtons = element.querySelector('.btn-group > .fc-customPrevious-button').parentElement

        showView(initialCalendarView)

        result
      })
  }

  function loadFullCalendar(){
    return Promise.all([
      import(/* webpackChunkName: "fullCalendar" */ '@fullcalendar/core'),
      import(/* webpackChunkName: "fullCalendar" */ '@fullcalendar/interaction'),
      import(/* webpackChunkName: "fullCalendar" */ '@fullcalendar/daygrid'),
      import(/* webpackChunkName: "fullCalendar" */ '@fullcalendar/timegrid'),
      import(/* webpackChunkName: "fullCalendar" */ '@fullcalendar/bootstrap'),
      import('jstimezonedetect'),
      import(/* webpackChunkName: "fullCalendar" */ '@fullcalendar/common'),
    ]).then(imports => {
      return {
        'Calendar': imports[0].Calendar,
        'interactionPlugin': imports[1].default,
        'dayGridPlugin': imports[2].default,
        'timeGridPlugin': imports[3].default,
        'bootstrapPlugin': imports[4].default,
        'jstz': imports[5].default,
        'createPlugin': imports[6].createPlugin,
      }
    })
  }

  function eventClick(info){
    processClick({ url: `${baseRoute}/${info.event.id}`, target: target })
  }

  function processClick(args){
    askModal(args).then(() => {
      calendar.refetchEvents()
    })
  }

  return () => {
    if (calendar) {
      calendar.destroy()
    }
  }
})
