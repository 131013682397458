// compiler to add a new question (nested record) to a survey, when a button is clicked
up.compiler('[survey-form]', (element) => {

  const questionsToBuildSelector = 'input[name="survey[questions_to_build]"]'
  const addQuestionSelector = '.survey-form--add-question-button'
  const questionsSelector = '.survey-form--questions'

  function onAddQuestion(event) {
    event.preventDefault()
    incrementQuestionsToBuild()

    up.util.muteRejection(up.validate(questionsToBuildSelector, { target: questionsSelector }))
    event.stopPropagation()
  }

  function incrementQuestionsToBuild() {
    let questionsToBuildInput = element.querySelector(questionsToBuildSelector)
    let current_value = parseInt(questionsToBuildInput.value)
    questionsToBuildInput.value = (current_value + 1)
  }

  up.on(element, 'click', addQuestionSelector, onAddQuestion)

})
