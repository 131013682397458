up.compiler('[privacy-setting-launcher]', ($element, data) => {
  // We won't show the modal on static pages
  if (isStaticPage()) { return }

  let url = encodeURI(data.url)
  up.layer.open({ url: url, target: '.content', dismissable: false, history: false })


  function isStaticPage() {
    const staticPages = ['/terms-of-use', '/privacy-policy', '/imprint']
    return staticPages.includes(window.location.pathname)
  }
});
