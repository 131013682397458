import { isMobile, isTablet } from '../util/is_mobile'

up.compiler('body', (element) => {
  let classList = element.classList

  if (isMobile()) {
    classList.add('-mobile')
  } else if (isTablet()) {
    classList.add('-tablet')
  } else {
    classList.add('-desktop')
  }
})
