/* eslint no-console:0 */
import 'core-js/stable' // ES6 polyfills
import 'regenerator-runtime/runtime' // more polyfills
import 'dom4' // DOM API polyfills

import 'unpoly/unpoly.es5' // Required for our current webpack 4 package (https://github.com/webpack/webpack/issues/10227)
import 'unpoly/unpoly.css'

// JavaScript
let webpackContext = require.context('../javascripts', true, /\.js(\.erb)?$/)
for(let key of webpackContext.keys()) { webpackContext(key) }

// Fonts
require.context('../fonts', true, /\.(?:woff2|woff)$/)

// Images
require.context('../images', true, /\.(?:png|jpg|gif|ico|svg)$/)

// Stylesheets
import '../stylesheets/custom_bootstrap'
import '../stylesheets/brand_font'
import '../stylesheets/icon_font'
import '../stylesheets/theme'

import 'mobius1-selectr/dist/selectr.min.css'
import '../stylesheets/custom_selectr'

require.context('../stylesheets/theme', true, /\.s[ac]ss(\.erb)?$/)
require.context('../stylesheets/blocks', true, /\.s[ac]ss(\.erb)?$/)
require.context('../stylesheets/ext', true, /\.s[ac]ss(\.erb)?$/)
