import { linkChecker } from '../util/link_checker.js'

up.macro('a', (element) => {

  // Open external links in a new tab
  if (linkChecker.isNoApplicationUrl(element)) {
    element.target = '_blank'
    element.rel = 'noopener'
  }

})
