up.compiler('[document-picker-abort]', (element) => {

  // This compiler adds a click handler which closes a
  // custom tinyMCE dialog without any further action.

  element.addEventListener('click', (event) => {
    event.preventDefault()

    window.parent.postMessage({
      mceAction: 'close'
    });
  })

})
