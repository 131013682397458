import { openPhotoSwipe } from '../util/photoswipe'

const OPENER_SELECTOR = '.lightbox-opener'

up.compiler('[lightbox-gallery]', (element) => {

  up.on(element, 'click', OPENER_SELECTOR, onClickOpener)

  function onClickOpener(event) {
    const galleryImages = element.querySelectorAll(OPENER_SELECTOR)
    const clickedImage = event.target.closest(OPENER_SELECTOR)

    openPhotoSwipe(galleryImages, clickedImage)
  }

})
