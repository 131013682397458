import Flow from '@flowjs/flow.js'
import { getCsrfParam, getCsrfToken } from '../util/csrf'
import { inTestEnvironment, inDevEnvironment } from '../util/environment'
import { settings } from "../settings"
import Timeout from '../util/timeout'

up.compiler('[file-chooser]', (element, data) => {

  const lessConnections = inDevEnvironment() || inTestEnvironment()

  const uploadPath = data.uploadPath
  const filesAttribute = data.filesAttribute || 'files'

  const input = element.querySelector('input[type=file]')
  const list = element.querySelector('[file-chooser--list]')
  const dropArea = element.querySelector('[file-chooser--drop-area]')
  const submitButton = element.querySelector('input[type=submit], button[type=submit]')
  const infoArea = element.querySelector('[file-chooser--info]')
  let refreshTimeout = null

  const flow = new Flow({
    target: uploadPath,
    query: getUploadParams,
    simultaneousUploads: lessConnections ? 2 : 3,
    testChunks: true, // Checks for each chunk if it has already been uploaded. Allows resuming large file uploads.
  })

  if (!flow.support) {
    alert('File uploads are unsupported on your device. Please use a recent browser.')
    return
  }

  function init() {
    updateState()
    list.classList.add('list-group')

    dropArea.addEventListener('dragenter', onDragEnter)
    dropArea.addEventListener('dragleave', onDragLeave)
    dropArea.addEventListener('drop', onDrop)

    up.on(element, 'click', '[file-chooser--remove-file]', onClickRemoveFile)

    flow.assignBrowse(input)
    flow.assignDrop(dropArea)

    flow.on('fileAdded', onFileAdd)
    flow.on('fileRemoved', onFileRemoved)
    flow.on('filesSubmitted', onFilesAdded)
    flow.on('fileProgress', onFileUploadProgress)
    flow.on('fileSuccess', onFileUploadSuccess)
    flow.on('fileError', onFileUploadError)
  }

  function onFileAdd(file, event) {
    createListItem(file)
  }

  function onFilesAdded(files, event) {
    flow.upload()
    updateState()
  }

  function onFileRemoved(file) {
    up.element.remove(getListItemFromFile(file))
    updateState()
  }

  function onFileUploadProgress(file, chunk) {
    setUploadProgress(file, file.progress())
    updateState()
  }

  function onFileUploadSuccess(file, message) {
    const listItem = getListItemFromFile(file)

    function addInput(attributeName, value) {
      let input = document.createElement('input')
      input.type = 'hidden'
      input.name = `${filesAttribute}[${file.uniqueIdentifier}][${attributeName}]`
      input.value = value
      listItem.append(input)
    }

    function pollCombinedChunksStatus() {
      let listItem = element.querySelector(`[unique-identifier='${file.uniqueIdentifier}']`)
      let uniqueIdentifierValue = listItem.getAttribute('unique-identifier')
      const totalCountValue = file.chunks.length
      const progressBar = listItem.querySelector('.progress-bar')
      const progress = progressBar.getAttribute('aria-valuenow')
      if (progress === "100") {
        return
      }

      up.request(settings.get('combinedChunksPoll', 'path'), { params: { batch_identifier: uniqueIdentifierValue }, cache: false })
      .then((response) => {
        const jsonResponse = JSON.parse(response.text)
        let combinedChunksCountFromResponse = jsonResponse.combined_chunks_count
        addInput('cache_name', jsonResponse.cache_name || '')
        setCombiningProgress(file, combinedChunksCountFromResponse, totalCountValue)
        updateState()
        triggerPoll()
      }).catch((errorResponse) => {
        if (errorResponse.status === 422) {
          let errorMessage = JSON.parse(errorResponse.text).error
          setFailed(file, errorMessage)
        }
      })
    }

    function triggerPoll() {
      refreshTimeout = new Timeout(pollCombinedChunksStatus, 2000)
    }

    triggerPoll()
    addInput('filename', file.name)
    addInput('relative_filename', file.relativePath)
    updateState()
  }

  function combining() {
    let listItems = element.querySelectorAll('[file-chooser--item]')
    let finishedItems = []

    listItems.forEach((listItem) => {
      const progressBar = listItem.querySelector('.progress-bar')
      const progress = progressBar.getAttribute('aria-valuenow')
      if (progress === "100") {
        finishedItems.push(listItem)
      }
    })

    return finishedItems.length !== listItems.length
  }

  function onFileUploadError(file, message) {
    let errorMessage
    try {
      errorMessage = JSON.parse(message).error
    } catch (_e) {
    }
    setFailed(file, errorMessage)
  }

  function getUploadParams(file, chunk, isTest) {
    if (isTest) {
      return {}
    } else {
      return { [getCsrfParam()]: getCsrfToken() }
    }
  }

  function createListItem(file) {
    const listItem = up.element.createFromHTML(`
      <div class="list-group-item" file-chooser--item unique-identifier="${file.uniqueIdentifier}">
        <div class="file-upload">
          <div class="file-upload--progress progress bg-light">
            <div class="progress-bar bg-info" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <div class="file-upload--label"></div>
          <div class="file-upload--actions">
            <button type="button" class="btn btn-sm btn-outline-danger" file-chooser--remove-file>✕</button>
          </div>
        </div>
      </div>
    `)
    listItem.setAttribute('id', file.uniqueIdentifier)
    listItem.querySelector('.file-upload--label').innerText = file.relativePath

    list.appendChild(listItem)
  }

  function getListItemFromFile(file) {
    return document.getElementById(file.uniqueIdentifier)
  }

  function setUploadProgress(file, progress) {
    const humanProgress = Math.floor(progress * 95)

    setProgress(file, humanProgress)
  }

  function setCombiningProgress(file, combinedChunksCount, totalCount) {
    const uploadProgress = 95
    const combineProgress = Math.floor((combinedChunksCount / totalCount) * 5)
    const fullProgress = uploadProgress + combineProgress

    setProgress(file, fullProgress)
  }

  function setProgress(file, progress) {
    const percent = `${progress}%`
    const listItem = getListItemFromFile(file)
    const progressBar = listItem.querySelector('.progress-bar')
    progressBar.style.width = percent
    progressBar.innerText = percent
    progressBar.setAttribute('aria-valuenow', progress)
  }

  function setFailed(file, message) {
    const listItem = getListItemFromFile(file)
    const progressBar = listItem.querySelector('.progress-bar')
    progressBar.classList.add('bg-danger')
    progressBar.style.width = '100%'
    progressBar.innerText = 'Error'
    if (message) {
      progressBar.setAttribute('tooltip', message)
      up.hello(progressBar)
    }
  }

  function onDragEnter(event) {
    dropArea.classList.add('-dragover')
  }

  function onDragLeave(event) {
    if (!dropArea.contains(event.relatedTarget)) {
      dropArea.classList.remove('-dragover')
    }
  }

  function onDrop() {
    dropArea.classList.remove('-dragover')
  }

  function onClickRemoveFile(event) {
    const listItem = event.target.closest('[file-chooser--item]')
    const file = flow.getFromUniqueIdentifier(listItem.id)

    if (confirm(`Remove “${file.relativePath}”?`)) {
      flow.removeFile(file)
    }
  }

  function updateState() {
    const seconds = flow.timeRemaining()
    const noFiles = flow.files.length < 1
    const uploading = flow.isUploading()

    if (seconds === Number.POSITIVE_INFINITY) {
      infoArea.innerText = 'Waiting …'
    } else if (seconds > 60) {
      const minutes = Math.round(seconds / 60)
      infoArea.innerText = `${minutes} minute${minutes === 1 ? '' : 's'} remaining`
    } else if (seconds > 0) {
      infoArea.innerText = `${seconds} second${seconds === 1 ? '' : 's'} remaining`
    } else {
      infoArea.innerText = ''
    }

    up.element.toggleClass(dropArea, '-empty', noFiles)

    submitButton.disabled = noFiles || uploading || combining()
    up.emit('flow:change')
  }

  init()

  return () => {
    flow.cancel()
    refreshTimeout?.clear()
  }

})
