// Observe all clicks that may open an external URL.
// In that case, the original event is canceled
// and the user must confirm a popup before being
// redirected.

import { linkChecker } from '../util/link_checker.js'

class ExternalURLHandler {
  constructor() {
    this.handleClick = this.handleClick.bind(this)
    this.ensurePopupForMouseWheelClicks = this.ensurePopupForMouseWheelClicks.bind(this)
    this.preventOpeningExternalUrls = this.preventOpeningExternalUrls.bind(this)
  }

  handleClick(event, target) {
    this.preventOpeningExternalUrls(event, target)
  }

  ensurePopupForMouseWheelClicks(event) {
    // Bubble up for left mouse clicks that trigger the click
    // event. Mouse wheel clicks trigger only the mousedown and
    // -up event, so we open the overlay in this case.
    const isMouseWheelClick = event.which === 2
    if (isMouseWheelClick) { this.handleClick(event) }
    return true
  }

  preventOpeningExternalUrls(event, target) {
    const preventOpeningURL = linkChecker.isExternalUrl(target)  && !externalURLOverlay.isOpen()
    if (preventOpeningURL) {
      event.preventDefault()
      this.showExternalUrlOverlay(target)
    }
    return preventOpeningURL
  }

  showExternalUrlOverlay(target) {
    return externalURLOverlay.show(target)
  }
}

class ExternalURLOverlay {
  constructor() {
    const externalUrlModalUrl = '/stacked_modal/external_url'
    this.modalOpen = false
    this.modalTemplate = undefined
    this.modal = undefined

    up.request(externalUrlModalUrl).then((response) => {
      this.modalTemplate = response.text
    }).catch(function() {
      console.warn('The request failed')
    })
  }

  isOpen() {
    return this.modalOpen
  }

  show(target){
    this.target = target
    this.modalOpen = true

    this.modal = up.element.createFromHTML(this.modalTemplate)
    document.body.append(this.modal)
    this.registerEvents()
  }

  registerEvents() {
    // click on Accept
    up.on(this.modal, 'click', '.btn-primary', () => {
      this.closeAndTriggerOriginal()
    })
    // Cancel button or background click
    up.on(this.modal, 'click', '.btn-link, .stacked-modal-viewport', () => {
      this.closeModal()
    })
  }

  closeAndTriggerOriginal() {
    this.clickTarget()
    this.closeModal()
  }

  closeModal() {
    this.modalOpen = false
    up.element.remove(this.modal)
  }

  clickTarget() {
    let clickDetachedElement = new MouseEvent('click', {view: window, bubbles: true, cancelable: true})
    this.target.dispatchEvent(clickDetachedElement)
  }
}
const externalURLOverlay = new ExternalURLOverlay

up.compiler('body', () => {
  up.on('click', 'a', new ExternalURLHandler().handleClick)
})
