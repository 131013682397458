import Superclamp from 'superclamp'

up.compiler('[clamp]', { batch: true }, (elements) => {
  setTimeout(() => {
    Superclamp.register(elements)
  })
})

function reclamp() {
  Superclamp.reclampAll()
}

window.addEventListener('resize', () => {
  setTimeout(reclamp)
});

if (document.fonts && document.fonts.load) {
  document.fonts.load('1rem VW Text').then(reclamp)
}
