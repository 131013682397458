import { settings } from '../settings.js'
import { hidden as appHidden } from '../util/visibility'

class MessageCountService {
  constructor() {
    this.currentCounts = undefined
    this.consumerCount = 0
    this.fetching = false
    this.lastReadIdByChannel = {}
  }

  schedule() {
    if ( !this.timeout ) {
      this.timeout = setTimeout(() => {
        this.timeout = setTimeout(() => {
          this.timeout = undefined
          this.fetchCounts()
        }, settings.get('messagePoll', 'interval'))
      }, settings.get('messagePoll', 'interval'))
    }
  }

  stop() {
    if ( this.timeout ) {
      clearTimeout(this.timeout)
      this.timeout = undefined
    }
  }

  refresh() {
    this.stop()
    this.fetchCounts()
  }

  fetchCounts() {
    if ( appHidden() ) {
      this.schedule()
    } else if ( !this.fetching ) {
      this.fetching = true

      window.CapybaraLockstep?.startWork('polling')
      up.request(settings.get('messagePoll', 'path'), { cache: false })
      .then((response) => {
        const counts = this.handleMarkAsRead(JSON.parse(response.text))
        let visibilitiesChanged = false
        if ( this.currentCounts ) {
          visibilitiesChanged = this.checkForChangedVisibilities(this.currentCounts, counts)
        }
        this.currentCounts = counts
        up.emit('update-message-counts', { counts, visibilitiesChanged })
      })
      .catch((response) => {
        if (response.status === 401) {
          // usually means that the user session expired -> redirect to signed out page
          up.visit('/')
        }
      })
      .finally(() => {
        window.CapybaraLockstep?.stopWork('polling')
        this.fetching = false
        this.schedule()
        this.lastReadIdByChannel = {} // reset this, since the server has enough time to get up to date
      })
    }
  }

  checkForChangedVisibilities(priorCounts, newCounts) {
    return !up.util.isEqual(Object.keys(priorCounts).sort(), Object.keys(newCounts).sort())
  }

  addUpdateListener(callback) {
    up.on('update-message-counts', callback)
    this.consumerCount++
    if ( this.currentCounts ) {
      callback({ counts: this.currentCounts })
      this.schedule()
    } else {
      this.fetchCounts()
    }
  }

  removeUpdateListener(callback) {
    up.off('update-message-counts', callback)
    this.consumerCount--
    if ( this.consumerCount <= 0 ) {
      this.consumerCount = 0
      this.stop()
    }
  }

  markAsReadIncludingOlder(subjectKey, id) {
    this.lastReadIdByChannel[subjectKey] = id
    // if for the channel with the subjectKey, our last known id is ID, assume the channel is read
    if ( this.currentCounts ) {
      this.currentCounts = this.handleMarkAsRead(this.currentCounts)
      up.emit('update-message-counts', { counts: this.currentCounts })
    }
  }

  handleMarkAsRead(counts) {
    let result = {}
    Object.entries(counts).forEach(([subjectKey, data]) => {
      if ( this.lastReadIdByChannel[subjectKey] === data.latest_id ) {
        // we have already read this, so assume count is 0
        result[subjectKey] = {
          type: data.type,
          count: 0,
        }
      } else {
        result[subjectKey] = data
      }
    })
    return result
  }
}

export const messageCountService = new MessageCountService()
