import { setPhotoSwipeData, openPhotoSwipe } from '../util/photoswipe'

up.compiler('[lightbox-image]', (element, data) => {

  const gallery = element.closest('[lightbox-gallery]')

  const url = data.url
  const width = data.width
  const height = data.height
  const previewUrl = data.previewUrl
  const caption = data.caption

  if (width && height) {
    setPhotoSwipeData(element, { url, width, height, previewUrl, caption })
    element.addEventListener('click', onClick)
    element.classList.add('lightbox-opener')
  }

  function onClick(event) {
    event.preventDefault()

    if (gallery) {
      // Will be handled by [lightbox-gallery]
    } else {
      openPhotoSwipe([element])
    }
  }

})
