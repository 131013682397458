up.compiler('[track-page-view]', (element, { dimensionIds, status, path, referrer, role }) => {

  const analytics = window.analytics

  analytics('setCustomUrl', path)
  analytics('setReferrerUrl', referrer)
  analytics('setCustomDimension', dimensionIds.status, status)
  analytics('setCustomDimension', dimensionIds.role, role)
  analytics('setCustomDimension', dimensionIds.modal, up.layer.isOverlay())
  analytics('setGenerationTimeMs', 0) // we don't know response times

  // Note that tracker methods like "setCustomDimension" should be called before "trackPageView"
  analytics('trackPageView')
  analytics('enableLinkTracking')

})
