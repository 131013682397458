import Flow from '@flowjs/flow.js'
import { inDevEnvironment, inTestEnvironment } from '../util/environment'
import { getCsrfParam, getCsrfToken } from '../util/csrf'

// UI styles
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/skin.mobile.min.css'


up.compiler('[wysiwyg]', (element, data) => {

  // Give content area the application stylesheet so WYSIWYG content appears as it will when placed in our HTML.
  const cssPath = data.cssPath
  const enableUploads = data.enableUploads
  const uploadPath = data.uploadPath
  const lessConnections = inDevEnvironment() || inTestEnvironment()

  let editor = undefined

  let baseConfig = {
    target: element,
    skin: false, // TinyMCE can't load styles properly inside Webpack. We loaded UI styles above.
    content_css: cssPath,
    body_class: 'p-2',
    min_height: 220,
    max_height: Math.min(600, window.innerHeight - 100),
    plugins: ['autoresize', 'lists', 'link', 'autolink', 'image', 'paste', 'table'],

    menubar: false,
    toolbar: 'undo redo | formatselect | forecolor | bold italic strikethrough superscript subscript | alignleft aligncenter alignright | bullist numlist | link addDocument image | removeformat | table ',
    contextmenu: false, // to enable copy & paste via native context menu

    formats: {
      strikethrough: { inline: 'del' },
      alignleft: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li', classes: 'text-left' },
      aligncenter: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li', classes: 'text-center' },
      alignright: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li', classes: 'text-right' },
    },

    custom_colors: false,
    color_map: [
      '00B0F0', 'Blue200',
      '001E50', 'Blue600',
      'E4002C', 'Red200',
      '029640', 'Green200',
    ],

    image_dimensions: false,
    image_class_list: [
      // caution: be very careful changing styles here. Old posts might be broken afterwards
      { title: 'Centered', value: 'editor-image--centered' },
      { title: 'Full width', value: 'editor-image--full-width' },
      // { title: 'Left', value: 'editor-image--left' },
      // { title: 'Right', value: 'editor-image--right' },
    ],

    paste_postprocess: function(plugin, args) {
      args.node.querySelectorAll('[class]').forEach((item) => {
        item.removeAttribute('class')
      })
    },

    setup: function(editor) {
      editor.ui.registry.addButton('addDocument', {
        icon: 'document-properties',
        tooltip: 'Insert document',
        onAction: openDocumentPicker,
      });
    },
  }

  let uploadConfig = {
    images_upload_handler: uploadHandler,
    automatic_uploads: true,
    paste_data_images: true,
    images_reuse_filename: true,
    relative_urls: false,
    remove_script_host: true,
  }

  let initConfig
  if (enableUploads) {
    initConfig = Object.assign(baseConfig, uploadConfig)
  } else {
    initConfig = baseConfig
  }

  loadTinyMce()
    .then(tinyMce => {
      return tinymce.init(initConfig)
    })
    .then(onInit)

  function loadTinyMce() {
    // we load tiny mce async for performance and pack size reasonse
    return Promise.all([
      import(/* webpackChunkName: "tinymce" */ 'tinymce/tinymce'),
      // UI styles
      import(/* webpackChunkName: "tinymce" */ 'tinymce/themes/silver'),
      import(/* webpackChunkName: "tinymce" */ 'tinymce/themes/mobile'),
      import(/* webpackChunkName: "tinymce" */ 'tinymce/icons/default/icons'),
      // Plugins
      import(/* webpackChunkName: "tinymce" */ 'tinymce/plugins/autoresize'),
      import(/* webpackChunkName: "tinymce" */ 'tinymce/plugins/lists'),
      import(/* webpackChunkName: "tinymce" */ 'tinymce/plugins/link'),
      import(/* webpackChunkName: "tinymce" */ 'tinymce/plugins/autolink'),
      import(/* webpackChunkName: "tinymce" */ 'tinymce/plugins/image'),
      import(/* webpackChunkName: "tinymce" */ 'tinymce/plugins/paste'),
      import(/* webpackChunkName: "tinymce" */ 'tinymce/plugins/table'),
    ])
    .then(([{ default: tinyMce }]) => {
      return tinyMce
    })
  }

  function onInit(editors) {
    editor = editors[0]
    if ( element.labels ) {
      // does not work on IE
      element.labels.forEach((label) => label.addEventListener('click', onClickLabel) )
    }

    if (inTestEnvironment) element.editorInstanceForTests = editor
  }

  function onClickLabel() {
    editor.focus()
  }

  function uploadHandler (blobInfo, success, failure, progress){
    const flow = new Flow({
      target: uploadPath,
      query: getUploadParams,
      simultaneousUploads: lessConnections ? 2 : 3,
      testChunks: true, // Checks for each chunk if it has already been uploaded. Allows resuming large file uploads.
    })

    if (!flow.support) {
      alert('File uploads are unsupported on your device. Please use a recent browser.')
      return
    }

    function onFileUploadSuccess(file, jsonString) {
      let jsonMessage = JSON.parse(jsonString)
      let location = jsonMessage.url
      success(location)

      function addEditorImageInput(value) {
        const form = element.closest('form')
        const editorImageAttributeName = 'post[transactional_editor_image_ids]'
        let input = document.createElement('input')
        input.type = 'hidden'
        input.name = `${editorImageAttributeName}[]`
        input.value = value
        form.append(input)
      }

      addEditorImageInput(jsonMessage.editor_image_id)
    }

    function onFileUploadError(file, message) {
      console.log(message)
      let errorMessage
      try {
        errorMessage = JSON.parse(message).error
      } catch (_e) {
      }
      failure('HTTP Error: ' + errorMessage);
    }

    function onFileProgress(file, chunk) {
      let intProgress = Math.floor(flow.progress() * 100)
      progress(intProgress)
    }

    flow.on('fileSuccess', onFileUploadSuccess)
    flow.on('fileError', onFileUploadError)
    flow.on('progress', onFileProgress)

    flow.addFile(blobInfo.blob())
    flow.upload()
  }

  function getUploadParams(file, chunk, isTest) {
    if (isTest) {
      return {}
    } else {
      return { [getCsrfParam()]: getCsrfToken() }
    }
  }

  return () => {
    if (editor) {
      if ( element.labels ) {
        // does not work on IE
        element.labels.forEach((label) => label.removeEventListener('click', onClickLabel))
      }
      editor.destroy()
    }
  }

  function openDocumentPicker () {
    editor.windowManager.openUrl({
      title: 'Please select a document',
      url: '/editor/directories',
    });
  }

})
