export function moveCaretToEnd(element) {

  if (element.value && element.setSelectionRange) {
    const length = element.value.length

    try {
      element.setSelectionRange(length, length)
    } catch(error) {
      // setSelectionRange is unsupported for some input types (e.g. email), throwing an error.
    }
  }

}
