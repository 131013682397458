import { messageCountService } from '../services/message_counts'

up.compiler('[sidebar]', (element) => {
  function onMessageCountChange({ visibilitiesChanged }) {
    if (visibilitiesChanged) {
      up.reload(element)
    }
  }

  messageCountService.addUpdateListener(onMessageCountChange)

  return () => {
    messageCountService.removeUpdateListener(onMessageCountChange)
  }
})
