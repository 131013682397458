class Settings {
  constructor() {
    this.data = {}
  }

 set(data) {
   return Object.assign(this.data, data)
 }

 get(...hierarchy) {
   let value = this.data
   for (let level of Array.from(hierarchy)) {
     value = value[level]
     if (value == null) { break }
   }
   return value
 }
}


export const settings = new Settings()
window.Settings = settings
