import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

let photoSwipeContainer = undefined

export function openPhotoSwipe(elements, clickedElement) {
  const elementsArray = [...elements]
  const items = elementsArray.map(image => image.photoSwipeData)
  const index = clickedElement ? elementsArray.indexOf(clickedElement) : 0

  // https://photoswipe.com/documentation/options.html
  const options = {
    index: index,
    bgOpacity: 0.9,
    clickToCloseNonZoomable: false,
    closeOnScroll: false,
    closeOnVerticalDrag: false,
    history: false,
    shareEl: false,
  }

  // Note that PhotoSwipe will automatically destroy itself when being closed.
  // It will also auto-close itself when users navigate back/forward.
  const photoSwipe = new PhotoSwipe(getPhotoswipeContainer(), PhotoSwipeUI_Default, items, options)
  photoSwipe.init()
}

export function setPhotoSwipeData(element, { url, width, height, previewUrl, caption }) {
  element.photoSwipeData = {
    src: url,
    msrc: previewUrl,
    w: width,
    h: height,
    title: caption,
  }
}

function getPhotoswipeContainer() {
  if (!photoSwipeContainer) {
    photoSwipeContainer = buildPhotoswipeContainer()
    document.body.append(photoSwipeContainer)
  }

  return photoSwipeContainer
}

function buildPhotoswipeContainer() {
  // Photoswipe requires a container element to use and requires it to be created by us.
  // HTML has been taken from the documentation: https://photoswipe.com/documentation/getting-started.html
  //
  return up.element.createFromHTML(`
    <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="pswp__bg"></div>

      <!-- Slides wrapper with overflow:hidden. -->
      <div class="pswp__scroll-wrap">

        <!-- Container that holds slides. 
            PhotoSwipe keeps only 3 of them in the DOM to save memory.
            Don't modify these 3 pswp__item elements, data is added later on. -->
        <div class="pswp__container">
          <div class="pswp__item"></div>
          <div class="pswp__item"></div>
          <div class="pswp__item"></div>
        </div>

        <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
        <div class="pswp__ui pswp__ui--hidden">

          <div class="pswp__top-bar">

            <!--  Controls are self-explanatory. Order can be changed. -->

            <div class="pswp__counter"></div>

            <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>

            <button class="pswp__button pswp__button--share" title="Share"></button>

            <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

            <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

            <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
            <!-- element will get class pswp__preloader--active when preloader is running -->
            <div class="pswp__preloader">
              <div class="pswp__preloader__icn">
                <div class="pswp__preloader__cut">
                  <div class="pswp__preloader__donut"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
            <div class="pswp__share-tooltip"></div>
          </div>

          <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
          </button>

          <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
          </button>

          <div class="pswp__caption">
            <div class="pswp__caption__center"></div>
          </div>

        </div>

      </div>

    </div>
  `)
}
