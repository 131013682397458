import 'rome/dist/rome.css'

// Inserts separate date and time fields for the given input, each with their own date/time picker.
// Changes from the (unnamed) fields are synced back to the origin element which can be sent to the server.
//
up.compiler('[datetime-picker]', (element, { validateMaximum, validateMinimum, placeholders }) => {

  let datePicker
  let timePicker
  let rome

  let validators = {}

  const allDayChangedEventName = 'app:dateTimePicker:allDayChanged'
  const inputGroup = up.element.createFromHTML('<div class="input-group"></div>')
  const dateAddon = up.element.createFromHTML('<div class="input-group-prepend"><div class="input-group-text" clickable><i class="icon -calendar -muted"></i></div></div>')
  const dateInput = up.element.createFromHTML('<input type="text" class="form-control date-input" />')
  const timeInput = up.element.createFromHTML('<input type="text" class="form-control time-input" />')
  const timeAddon = up.element.createFromHTML('<div class="input-group-append"><div class="input-group-text" clickable><i class="icon -clock -muted"></i></div></div>')

  updatePlaceholders()

  let [dateValue, timeValue] = element.value ? element.value.match(/(\d+-\d+-\d+) (\d+:\d+)/).slice(1, 3) : []

  loadRome()
  .then(init)

  return () => {
    datePicker && datePicker.destroy()
    timePicker && timePicker.destroy()
  }

  function loadRome() {
    return import(/* webpackChunkName: "rome" */ 'rome')
    .then(({ default: importedRome }) => {
      rome = importedRome
    })
  }

  function init() {
    dateInput.value = dateValue || ''
    timeInput.value = timeValue || ''

    inputGroup.appendChild(dateAddon)
    inputGroup.appendChild(dateInput)
    inputGroup.appendChild(timeInput)
    inputGroup.appendChild(timeAddon)
    element.after(inputGroup)

    if (element.matches('.is-invalid')) {
      dateInput.classList.add('is-invalid')
      timeInput.classList.add('is-invalid')
    }

    datePicker = rome(dateInput, getDatePickerOptions())
    timePicker = rome(timeInput, getTimePickerOptions())

    datePicker.on('data', onNewDate)
    timePicker.on('time', onNewTime)

    // Rome won't trigger events when emptying inputs so we handle that ourselves:
    dateInput.addEventListener('change', () => { if (!dateInput.value) onNewDate('') })
    timeInput.addEventListener('change', () => { if (!timeInput.value) onNewTime('') })

    dateAddon.addEventListener('click', () => { setTimeout(datePicker.show, 100) })
    timeAddon.addEventListener('click', () => { setTimeout(timePicker.show, 100) })

    element.addEventListener(allDayChangedEventName, onAllDayChanged)

    element.dateInput = dateInput // Used by this compiler to look up other date picker for validation
    element.timeInput = timeInput
    element.type = 'hidden'
  }

  function onAllDayChanged(event) {
    if (event.allDay) {
      timeInput.remove()
      timeAddon.remove()
    } else {
      inputGroup.appendChild(timeInput)
      inputGroup.appendChild(timeAddon)
    }
  }

  function updateElementValue() {
    element.value = dateValue && `${dateValue} ${timeValue}`
  }

  function onNewDate(newDateValue) {
    dateValue = newDateValue
    updatePlaceholders()
    updateElementValue()
  }

  function onNewTime(newTimeValue) {
    timeValue = newTimeValue
    updatePlaceholders()
    updateElementValue()
  }

  function validateDate(newDate) {
    const validator = getValidator('dateInput')
    return validator && validator(newDate)
  }

  function validateTime(newTime) {
    const validator = getValidator('timeInput')
    return validator && validator(newTime)
  }

  function getValidator(inputName) {
    if (!validators[inputName]) {
      let validationName, otherElementSelector

      if (validateMaximum) [validationName, otherElementSelector] = ['beforeEq', validateMaximum]
      if (validateMinimum) [validationName, otherElementSelector] = ['afterEq', validateMinimum]

      if (validationName && otherElementSelector) {
        const otherElement = up.element.get(otherElementSelector)
        const otherInput = otherElement && otherElement[inputName]
        const validator = otherInput && rome.val[validationName](otherInput)

        if (!validator) {
          // Can't validate yet.
        } else if (inputName === 'timeInput') {
          validators[inputName] = function(newTime) {
            // Time inputs will only be limited on the same date
            if (dateInput.value && otherElement['dateInput'] && dateInput.value === otherElement['dateInput'].value) {
              return validator(newTime)
            } else {
              return true
            }
          }
        } else {
          validators[inputName] = validator
        }
      }
    }

    return validators[inputName]
  }

  function getDatePickerOptions() {
    return {
      weekStart: 1, // Monday
      weekdayFormat: 'short', // Mon
      time: false,
      dateValidator: validateDate,
      styles: {
        // Picker overlay
        container: 'rd-container border rounded-lg shadow bg-white p-2',

        // Top bar
        back: 'px-3 py-1 rd-back',
        next: 'px-3 py-1 rd-next',
        monthLabel: 'py-1',

        // Month sheet
        dayTable: 'table table-sm table-borderless m-0',

        // Because Rome uses some "style" Strings as selectors to locate elements, using the a multi-class string or
        // using the same class for multiple elements breaks functionality. We've overwritten some CSS styles.
        // The following styles can not be set without side effects:
        // - dayPrevMonth
        // - dayNextMonth
        // - selectedDay
      },
    }
  }

  function getTimePickerOptions() {
    return {
      date: false,
      timeValidator: validateTime,
      timeFormat: 'hh:mm a',
      styles: {
        container: 'rd-container p-0 border-0',
        time: 'm-0',
        selectedTime: 'd-none',
        timeList: 'rd-time-list dropdown-menu shadow m-0 time-dropdown',
        timeOption: 'dropdown-item',
      },
    }
  }

  function updatePlaceholders() {
    if ( placeholders && !(timeValue || dateValue) ) {
      dateInput.setAttribute('placeholder', placeholders[0])
      timeInput.setAttribute('placeholder', placeholders[1])
    } else {
      dateInput.removeAttribute('placeholder')
      timeInput.removeAttribute('placeholder')
    }
  }

})
